import * as React from "react"
import styled from 'styled-components'
import Layout from '../components/Layout'
// markup
const IndexPage = () => {
  const StyledH3 = styled.h3`
    padding: 1em;
  `
  const StyledP = styled.p`
    padding: 1em;
  `
  const StyledH5 = styled.h5``
  return (
    <Layout>
        <StyledH3>Are you considering therapy?</StyledH3>
        <StyledP>
          It can be frightening to take the steps necessary for change. Having someone you can trust and feel understood by, without judgment, can greatly assist you through this process. I strongly believe in the value of the therapeutic relationship between client and therapist and how this relationship can help you. I am committed to providing empathetic treatment that progresses at your speed and focuses on your personal goals. If you are simply looking to feel better than you do now, need some guidance or support, or have more specific issues, such as trauma, depression, anxiety, anger problems, relationship issues, substance abuse issues, etc. I can help you. I want you to know that change is possible and there is hope for you. Step one is realizing that you need help and cannot do this alone. I can help you through the rest. 
        </StyledP>
        <StyledH5>
            "You are not responsible for being down, but you are responsible for getting up." - Jesse Jackson
        </StyledH5>
    </Layout>
  )
}

export default IndexPage
